<template>
  <transition name="modal">
    <div class="modal-mask" style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" style="max-height: 560px; min-height: 300px; width: 770px !important; overflow: scroll;margin-top: 70px; padding: 24px; background-color: white;" @click.stop>
        <div>
          <div style="display: flex; align-items: center; color: #2d3640; height: 24px; background-color: white; border-radius: 8px 8px 0 0; margin-bottom: 26px">
            <div style="flex:1; text-align: left; color: #353C48">
              Teklif Gönder
            </div>
            <div style=" flex: 1; text-align: right;">
              <img @click="hide()" style=" opacity: 1; cursor:pointer;width: 24px; height: 24px;"  src="https://gcdn.bionluk.com/site/cicons/close_circle.svg">
            </div>
          </div>
          <div v-if="showOfferSection">
            <div v-if="type === 'buyerRequest'" style="display: flex;" class="mb-3">
              <div style="display: inline-block; max-width: 150px; text-align: center; ">
                <img :src="request.buyer.avatar_url" style="width: 62px; height: 62px; border: 2px solid #EAEAEA; border-radius: 50%"/>
                <p style="font-size: 12px; color: #808896;">{{ request.buyer.username }}</p>
              </div>
              <div style="margin-left: 30px;">
                <label style="font-size: 15px; color: #4B4F52;">{{ request.title }}</label>
                <div style="display: inline-block; max-height: 90px; overflow-y: auto;">
                  <span v-html="request.description" style="font-size: 12px; color: #808896; line-height: 20px;"></span>
                </div>
              </div>
            </div>
            <div v-if="gigs.length">
              <custom-select label="İş İlanı Seç"
                             id="mainCategory"
                             v-model="selectedGig"
                             :options="gigs"
                             :customOption="{name: 'title', value:'uuid'}"
                             style="width:100%; margin-bottom: 2px;"
              />
            </div>
            <div v-else class="checkout-warning">
              <img style="margin-left: 20px;" src="https://gcdn.bionluk.com/site/icon/icon_warning_red.svg">
              <p class="warning-header">Uyarı</p>
              <div class="separator"></div>
              <p class="warning-text">
                Aktif bir İş ilanın bulunmadığı için teklif veremezsin.
              </p>
              <router-link to="/panel/ilanlar">
              <button class="warning-action-button">
                İş İlanlarım
              </button>
              </router-link>
            </div>

            <div v-if="type !== 'buyerRequest' || selectedGig" class="mt-2">
              <span style="font-size: 12px;">📌 Tekliflerde vereceğin detaylı bilgi seçilme şansını arttırır. 🤓 Ne kadar çok bilgi o kadar çok sipariş!</span>
              <custom-input
                label="Teklif Mesajı"
                inputType="textArea"
                placeHolder=""
                :maxlength="600"
                v-model="description"
                :valid="!!description ? true : null"
                class="mt-1"
                style="width:auto; height: 110px;"
              />
              <div class="mt-2" style="display: flex; justify-content: space-between">
                <div style="display: flex">
                  <div style=" height: 120px;">
                    <custom-input label="₺ İstenilen ücret" input-type="number" v-model="price" :valid="!!price ? true : null" class="mt-2" style="width:auto;" />
                    <p style="font-size: 12px; margin-top: 5px">Min: {{ orderType === 'custom' ? '100' : '50' }}TL. Sipariş sonunda yorum ve puan {{orderType === 'custom' ? 'verilebilir' : 'verilemez'}}.</p>
                  </div>
                  <custom-input label="Süre (gün)" input-type="number" v-model="duration" :valid="!!duration ? true : null" class="mt-2 ml-2" style="width:auto; height: 120px;" />
                </div>
                <div style="align-self:flex-end; margin: 10px 0">
                  <custom-button :button-type="'green'" :disabled="disableToSend" style="width: 150px;" @click="sendOffer">Gönder</custom-button>
                  <p v-if="!gigs.length" style="font-size: 12px; margin-top: 5px; ">Aktif iş ilanın yok.</p>
                </div>

              </div>
            </div>
          </div>

          <div v-else>

            <div class="main-select-container" style="display: flex ">

              <div class="select-img-container" :style="orderType === 'custom' ? 'border: solid 1px rgb(38, 202, 154);' : ''" @click="selectType('custom')">
                ÖZEL SİPARİŞ
                <div style="padding-top: 10px; font-size: 13px;">Min: 100 TL</div>
              </div>
              <div class="select-img-container" :style="orderType === 'taslak' ? 'border: solid 1px rgb(38, 202, 154);' : ''" @click="selectType('taslak')">
                TASLAK
                <div style="padding-top: 10px; font-size: 13px;" >Min: 50 TL</div>
              </div>
              <div class="select-img-container" :style="orderType === 'revize' ? 'border: solid 1px rgb(38, 202, 154);' : ''" @click="selectType('revize')">
                REVİZE
                <div style="padding-top: 10px; font-size: 13px;">Min: 50 TL</div>
              </div>
              <div class="select-img-container" :style="orderType === 'ek' ? 'border: solid 1px rgb(38, 202, 154);' : ''" @click="selectType('ek')">
                EK HİZMET
                <div style="padding-top: 10px; font-size: 13px;">Min: 50 TL</div>
              </div>
            </div>
            <div v-if="orderType"  class="checkout-warning">
              <img style="margin-left: 20px;" src="https://gcdn.bionluk.com/site/icon/icon_warning_red.svg">
              <p class="warning-header">Uyarı</p>
              <div class="separator"></div>
              <p v-if="orderType === 'custom'" class="warning-text">
                Min: 100TL Sipariş tamamlandığında <span style="font-weight: bold">yorum & puan verilebilir.</span>
              </p>
              <p v-else class="warning-text">
                Min: 50TL Sipariş tamamlandığında <span style="font-weight: bold">yorum & puan verilemez.</span>
              </p>

                <button class="warning-action-button" @click="showOfferSection = true;">
                  Devam Et
                </button>

            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import CustomSelect from "@/pages/components/CustomSelect"
  import CustomInput from "@/pages/components/CustomInput"
  import CustomButton from "@/pages/components/CustomButton";
  export default {
    name: "src-pages-modals-offerForBuyerRequest-v1",
    components: {CustomButton, CustomInput, CustomSelect },
    data () {
      return {
        type: 'buyerRequest',
        request: {},
        receiver: null,
        gigs: [],
        selectedGig: null,
        description: null,
        price: null,
        duration: null,
        pastOfferDetail: {},
        disable: false,
        showOfferSection: false,
        orderType: null
      }
    },
    methods: {
      selectType(type){
        this.orderType = type;
      },
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL)
      },
      sendOffer() {
        this.disable = true
        const type = this.type === 'buyerRequest' ? this.Constants.CUSTOM_OFFER_TYPES.REQUEST : this.Constants.CUSTOM_OFFER_TYPES.OFFER
        const uuid = this.type === 'buyerRequest' ? this.request.uuid : null
        const receiverUuid = this.type === 'buyerRequest' ? this.request.buyer.uuid : this.receiver.uuid
        this.api.seller.sendCustomOffer(receiverUuid, this.description, this.selectedGig, this.duration, this.price, type, uuid, this.orderType, this.$Progress)
        .then(({ data: result }) => {
          if (result.success) {
            this.$toasted.global.infoToast({description: result.message})
            if (this.type === 'buyerRequest') {
              this.EventBus.$emit('removeRequestFromMainPage', this.request.request_id)
              this.request.pastOffer = result.data.pastOffer
              this.request.pastOfferDetail = result.data.offerDetail
              this.$store.commit(this.types.DISABLE_MODAL)
            } else {
              return this.api.workstation.sendMessage(result.data.type, this.openedChannelID, null, result.data.external_id)
                .then(({data}) => {
                  let result = data;
                  if (result.success) {
                    this.$store.commit(this.types.DISABLE_MODAL);
                  } else {
                    this.$toasted.global.errorToast({description: result.message});
                  }
                })
            }
          } else {
            this.$toasted.global.errorToast({description: result.message})
          }
        })
        .catch(() => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE})
        })
        .finally(() => {
          this.disable = false
        })
      }
    },
    computed: {
      getModalInfo() {
      	if (!this.gigs.length) {
          let data = this.$store.state.activeModal.info.data
          this.request = data.request
          this.gigs = data.gigs
          if (data.type !== 'buyerRequest') {
            this.type = data.type
            this.receiver = data.receiver;
            this.openedChannelID = data.openedChannelID;
          }
        }
        return true
      },
      disableToSend() {
      	return !this.gigs.length || !(this.description && this.description.trim().length) || !this.selectedGig || !this.price || !this.duration || this.disable
      }
    },
    mounted() {

    }
  }

</script>

<style lang="scss" scoped>

.main-select-container{
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  height: 180px;
  .select-img-container{
    transition: all ease-in 0.1s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 160px;
    height: 160px;
    border-radius: 12px;
    border: solid 1px rgba(191, 200, 210, 0.2);
    background-color: #ffffff;
    .select-img{
      width: 160px;
    }
    .select-text{
      font-size: 18px;
      font-weight: normal;
      text-align: center;
      color: #8b95a1;
      margin-top: 24px;
      width: 100px;
    }
    &:hover{
      width: 160px;
      height: 160px;
      border-radius: 12px;
      box-shadow: 0 20px 20px 0 #e9ebf0;
      border: solid 1px #26ca9a;
      background-color: #ffffff;
      .select-text{
        color: #2d3640;
      }
    }
  }

}

  .checkout-warning {
    height: 70px;
    margin-top: 27px;
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(253, 241, 238);
    border: 1px solid rgb(232, 186, 186);
  }

  .warning-header {
    font-size: 18px;
    font-weight: 500;
    margin-left: 10px;
    color: rgb(242, 52, 52);
  }

  .warning-text {
    margin-left: 17px;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: rgb(242, 52, 52);
  }

  .separator {
    height: 30px;
    width: 0;
    margin-left: 19px;
    border-left: solid 1px #e7b9b9;
  }

  .warning-action-button {
    width: 110px;
    height: 44px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #c6c8ca;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
    margin-left: 20px;
    margin-right: 20px;
  }

  .warning-action-button:hover {
    background-color: #fbfbfb !important;
  }

</style>
